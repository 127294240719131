.toolbar-title {
    opacity: 1 !important;
}

.birthdate-icon {
    font-size: large;
    margin-right: 2rem;
    ;
}

.button-icon {
    margin-right: 0.5rem;
}

.photo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.photo {
    width: 30rem!important;
    height: 20rem !important;
}


/* .photo::part(image) {
    border-radius: 50rem !important;
    box-shadow: 0 3px 3px -2px rgb(0 0 0 / 20%), 0 3px 4px 0 rgb(0 0 0 / 14%), 0 1px 8px 0 rgb(0 0 0 / 12%);
  } */

.photo-button {
    font-size: x-large;
    --border-radius: 35rem;
    --padding-start: 0.8rem;
    --padding-end: 0.4rem;
    --padding-top: 2rem;
    --padding-bottom: 2rem;
}

.search-button {
    font-size: medium;
    --border-radius: 15rem;
    --padding-start: 0.1rem;
    --padding-end: 0.1rem;
    --padding-top: 0.1rem;
    --padding-bottom: 0.1rem;
}

.label-margin-bottom {
    margin-bottom: 0.8rem;
}

.language-select {
    font-size: large;
    color: #5bc9d2;
    background-color: #ecfafb;
    border-radius: 25rem;
}

.initial-input {
    font-size: large;
    color: #5bc9d2;
    background-color: #ecfafb;
    border-radius: 25rem;
    --padding-start: 1.2rem;
}