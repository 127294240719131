.font-weight-bold {
    font-weight: bold !important;
}

.text-underline {
    text-decoration: underline;
}

.myframe {
    /* position: absolute; */
    width: 792px;
    height: 1060px;
    border: none
}