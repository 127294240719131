.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    background-color: #ffffff;
}

.login-controls {
    width: 100%;
}

.image-container {
    display: flex;
    justify-content: center;
}

.bajo-title-img {
    width: 15rem !important;
}

.title-img {
    width: 15rem !important;
    margin-bottom: 3rem;
}

.qr-code-img {
    width: 5rem !important;
    margin-bottom: 2rem;
    box-shadow: 0 3px 3px -2px rgb(0 0 0 / 20%), 0 3px 4px 0 rgb(0 0 0 / 14%), 0 1px 8px 0 rgb(0 0 0 / 12%);
    cursor: pointer;
}

.button-container {
    margin-top: 2rem;
}