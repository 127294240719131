*, *:after, *:before {
    box-sizing: border-box;
}

.radio {
    display: inline-flex;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    align-items: center;
    padding-left: 0;
    margin-right: .75rem;
    /* Accessible outline */
    /* Remove comment to use */
    /* &:focus-within {
		 outline: .125em solid $primary-color;
	}
	 */
}

    .radio input {
        position: absolute;
        left: -9999px;
    }

        .radio input:checked + label {
            /*background-color: #fff9e7;*/
            background: rgba( 255, 255, 255, 0.30 );
            cursor: pointer;
        }

            .radio input:checked + label:before {
                box-shadow: inset 0 0 0 0.4375em #0A263E;
            }

    .radio label {
        display: flex;
        align-items: center;
        padding: 0.375em 0.75em 0.375em 0.375em;
        border-radius: 99em;
        transition: 0.25s ease;
    }

        .radio label:hover {
            /*background-color: #fff9e7;*/
            background: rgba( 255, 255, 255, 0.30 );
            cursor: pointer;
        }

        .radio label:before {
            display: flex;
            flex-shrink: 0;
            content: "";
            /*background-color: #fff;*/
            background: rgba( 255, 255, 255, 0.90 );
            width: 1.5em;
            height: 1.5em;
            border-radius: 50%;
            margin-right: 0.375em;
            transition: 0.25s ease;
            box-shadow: inset 0 0 0 0.125em #0A263E;
        }

    .radio input:disabled + label {
        /*background-color: #e9ecef !important;*/
        background: rgba( 0, 0, 0, 0.10 );
        cursor: not-allowed !important;
    }

        .radio input:disabled + label:before {
            box-shadow: inset 0 0 0 0.125em gray !important;
        }

    .radio input:checked:disabled + label:before {
        box-shadow: inset 0 0 0 0.4375em gray !important;
    }
