.applicant-card{
    width: 20rem;
    height: 21rem;
    /* box-shadow: 0 3px 3px -2px rgb(255 193 0 / 20%), 0 3px 4px 0 rgb(255 193 0 / 14%), 0 1px 8px 0 rgb(255 193 0 / 12%) !important; */
    /* box-shadow: 0 3px 3px -2px rgb(146 221 226 / 20%), 0 3px 4px 0 rgb(146 221 226 / 14%), 0 1px 8px 0 rgb(146 221 226 / 12%) !important; */
    box-shadow:0 3px 3px -2px rgb(146 221 226 / 40%), 0 3px 4px 0 rgb(146 221 226 / 28%), 0 1px 8px 0 rgb(146 221 226 / 24%) !important;

}

.applicant-card-header{
    
}

.applicant-card-content{
   
}

.applicant-card-detail{
    display: flex;
    align-items: center;
}

.applicant-card-detail-label{
   margin-left: 0.5rem;
}

.header-img{
    height: 10rem !important;
}

image {
    object-fit: fill !important;
    border: 1px solid rgb(215, 216, 218);
    display: block;
    width: 50%;
    height: 100%;
    object-position: inherit;
    border-radius: 31rem;
}

.header-img::part(img){
    object-fit: fill !important;
    border: 1px solid rgb(215, 216, 218);
    display: block;
    width: 50%;
    height: 100%;
    object-position: inherit;
    border-radius: 31rem;
}

.ion-img::part(img) { 
    height: 100%; 
    width: 100%; 
    object-fit:fill !important; 
  }