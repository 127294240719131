.concent-label-value{
    background-color: #f4f5f8;
    border-radius: 0.25rem;
    padding: 1rem;
    box-shadow: inset 0 0 9px #d7d8da;
}

.concent-label{
    color: #383a3e;
    font-weight: 600;
}

.list-alpha-style{
    list-style-type: lower-alpha;
}

.mr-2 {
    margin-right: 0.5rem;
}

.underline {
    margin-top: 2rem !important;
    border-bottom: 1px solid black;
}

.danger {
    color: red;
}

.text-center{
    text-align:center;
}

.d-inline{
    display: inline;
}

.d-inline-block{
    display: inline-block;
}

.font-bold{
    font-weight: bold;
}