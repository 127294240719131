.toolbar-title{
    opacity: 1 !important;
}

.birthdate-icon{
    font-size: large;
    margin-right: 2rem;;
}

.button-icon{
    margin-right: 0.5rem;
}

.font-weight-bold{
    font-weight: bold !important;
}

p,label,li{
    text-align: justify;
}

.form-check label{
    text-align: justify !important;
}

.signature-canvas-container{
    border-radius: .5rem;
    box-shadow: inset 0 0 18px #5bc9d2;
    background-color: #ecfafb;
    height: 12rem;
}

.signature-canvas{
   width: 100% !important;
   height: 100% !important;
}

.color-picker{
    background-color: #ecfafb;
    border-radius: 25rem;
}

