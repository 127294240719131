ion-button{
    --border-radius: 25rem;
}

body{
    --ion-font-family: 'poppins', sans-serif !important;
    overflow-x: hidden !important;
}

.process-loader{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 5000;
    background-color: rgb(0, 0, 0,0.3);
}

.process-grid-loader{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}